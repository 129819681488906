@include govuk-exports("fds-pane") {
  $toc-width: 260px;
  $toc-width-tablet: 210px;
  $subnav-width-small: 130px;
  $subnav-width-small-tablet: 110px;

  // Custom component from GOVUK Design System
  // Used as a wrapper for a custom layout not from govuk-frontend
  .fds-pane {
    &--enabled {
      @include govuk-media-query($from: tablet) {
        display: flex;
        flex-direction: column;
      }
    }

    &__back-link {
      width: 100%;
    }

    &__body {
      @include govuk-media-query($from: tablet) {
        display: flex;
        position: relative;
        min-height: 0;
        overflow: inherit;
      }

      @include govuk-media-query(1160px) {
        width: 100%;
      }
    }

    &__subnav {
      @include govuk-media-query($from: tablet) {
        width: $toc-width-tablet;
        flex: 0 0 auto;
      }
      @include govuk-media-query($from: desktop) {
        width: $toc-width;
      }

      &--small {
        @include govuk-media-query($from: tablet) {
          width: $subnav-width-small-tablet;
        }
        @include govuk-media-query($from: desktop) {
          width: $subnav-width-small;
        }
      }
    }

    &__content {
      @include govuk-media-query($from: tablet) {
        display: flex;
        min-width: 0;
        flex: 1 1 100%;
        flex-direction: column;
      }
    }
  }

  .no-flexbox.no-flexboxtweener {
    .fds-pane {
      height: auto;
      overflow: visible;
      @include govuk-clearfix;
    }

    .fds-pane__body {
      display: block;
    }

    .fds-pane__subnav {
      width: $toc-width;
      float: left;
      overflow-x: hidden;
      border-right: 0;

      &--small {
        width: $subnav-width-small;
      }
    }

    .fds-pane__content {
      margin-left: -1px;
      overflow-x: hidden;
    }
  }
}