// Task list component

.fds-task-list {
  &-multiple {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__section-heading {
    display: flex;
    @include govuk-font($size:24, $weight: bold);

    &-number {
      @include govuk-media-query($from: tablet) {
        min-width: govuk-spacing(6);
        padding-right: 0;
      }
    }
  }

  &--has-section-number {
    padding-left: 0;
    @include govuk-media-query($from: tablet) {
      padding-left: govuk-spacing(6);
    }
  }
}
