.fds-result-list {
  @include govuk-font(19);
  padding-left: 0;
  list-style-type: none;
  margin: 0 0 govuk-spacing(2) 0;
  border-top: 1px solid $govuk-border-colour;

  &__count-heading {
    @include govuk-responsive-margin(2, "bottom");
  }

  &__item {
    border-bottom: 1px solid $govuk-border-colour;
    margin-bottom: 0;
    @include govuk-responsive-padding(2, "top");
    @include govuk-responsive-padding(1, "bottom");
  }

  &__content {
    display: flex;
    justify-content: space-between;

    &-heading {
      @include govuk-responsive-margin(0, 'bottom');
      @include govuk-responsive-margin(0, 'top');
    }
  }

  &__data-items {
    @include govuk-responsive-margin(1, 'top');
    @include govuk-responsive-margin(0, 'bottom');
  }
}