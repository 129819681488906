// Custom GOVUK footer styles
.govuk-footer {
  @include govuk-media-query($media-type: print) {
    display: none;
  }
}

$fds-nsta-footer-logo-image-width: 112px;
$fds-nsta-footer-logo-image-height: 113px;

.fds-nsta-footer__logo {
  display: inline-block;
  min-width: $fds-nsta-footer-logo-image-width;
  padding-top: $fds-nsta-footer-logo-image-height;
  background-image: fds-image-url("nsta.png");
  @include govuk-device-pixel-ratio {
    background-image: fds-image-url("nsta-2x.png");
  }
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: $fds-nsta-footer-logo-image-width $fds-nsta-footer-logo-image-height;
  text-align: center;
  white-space: nowrap;
}
