// Custom GOVUK error styles
.govuk-error-summary {
  // Print styles
  @include govuk-media-query($media-type: print) {
    display: none;
  }
}

.govuk-error-message {
  &--inline {
   margin-bottom: govuk-spacing(0);
  }
}