// FDS helper mixin for hidden form controls, used to hide form controls that are replaced with JS-enhanced versions.
// This is different to .govuk-visually-hidden because these controls have to remain on-screen for Dragon to be able to
// interact with them.
@mixin fds-hidden-form-control {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  z-index: 1 !important;
}