// Fivium Design System Example
.fds-code-sample-wrapper {
  @include govuk-responsive-margin(6, "top");
  @include govuk-responsive-margin(6, "bottom");
  @include govuk-font(19);

  .fds-code-sample {
    position: relative;
    border: 1px solid $govuk-border-colour;
    @include govuk-responsive-margin(0, "bottom");

    @include govuk-media-query($from: desktop) {
      margin-bottom: -1px;
    }

    &__page {
      padding: govuk-spacing(6);

      &--iframe {
        padding: govuk-spacing(0);
      }
    }

    &__rendered {
      &:not(:last-child) {
        margin: 0 0 1em 0;
      }
    }
  }
}

// =========================================================
// Code blocks
// =========================================================

.fds-code-block {
  margin-bottom: govuk-spacing(4);

  &__pre {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
  }

  &__code {
    display: block;
    flex: 1;
    background-color: govuk-colour("light-grey");
    border: 1px solid transparent;
    padding: govuk-spacing(4);
    overflow-x: auto;
    @include govuk-font($size: 19);
    font-family: monospace;
  }
}

.fds-code-sample-tabs__container {
  padding: 20px;
  border: 1px solid $govuk-border-colour;
  background-color: govuk-colour("white");

  // When used for tabs, position to underlap tabs
  @include govuk-media-query($from: desktop) {
    margin-top: -2px;
  }
}

.fds-code-sample-tabs__container--hidden {
  display: none;
}

// Example tabs

// =========================================================
// Tabs (desktop)
// =========================================================

.fds-code-sample-tabs {
  margin: -1px auto;
  padding: 0;
  overflow: visible;
  list-style-type: none;
  border: 1px solid $govuk-border-colour;
  display: flex;

  @include govuk-media-query($until: desktop) {
    display: none;
  }
}

.fds-code-sample-tabs__item {
  @include govuk-font(19);
  position: relative;
  padding: govuk-spacing(4);

  a {
    display: block;
    color: $govuk-link-colour;

    &:visited {
      color: $govuk-link-colour;
    }

    &:focus {
      @include govuk-focused-text;
      color: $govuk-focus-text-colour;
    }

    // Extend the touch area of the <a> to fill the entire tab
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.fds-code-sample-tabs__item--current {
  border-right: 1px solid $govuk-border-colour;
  border-left: 1px solid $govuk-border-colour;
  background: govuk-colour("white");

  // No left hand border is required for the first tab, as it would just double
  // up the border of its parent
  &:first-child {
    border-left: 0;
  }

  a {
    color: govuk-colour("black");
    text-decoration: none;
  }
}

// =========================================================
// 'Accordion' (mobile and tablet)
// =========================================================

.fds-code-sample-tabs__heading {
  display: none;
  position: relative;
  padding: govuk-spacing(3);
  border: 1px solid $govuk-border-colour;
  border-top: 0;

  @include govuk-media-query($until: desktop) {
    display: block;
  }

  a {
    color: $govuk-link-colour;
    // Extend the touch area of the <a> to fill the entire heading
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:visited {
      color: $govuk-link-colour;
    }

    &:focus {
      @include govuk-focused-text;
      color: $govuk-focus-text-colour;
    }
  }
}

.fds-code-sample-tabs__heading--current {
  border-bottom: 0;

  a {
    text-decoration: none;
  }
}


// =========================================================
// iFrame examples
// =========================================================

.fds-example-frame {
  display: block;
  width: 100%;
  max-width: 100%;
  border: 0;
  border-right: 1px solid $govuk-border-colour;
  background: govuk-colour("white");

  &--resizeable {
    min-width: 230px;
    min-height: govuk-spacing(6) * 2;
    overflow: auto;
    transform: translate3d(0, 0, 0);
    @include govuk-media-query($from: desktop) {
      resize: both;
    }
  }
}