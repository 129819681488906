// Custom GOVUK table styles
.govuk-table {
  &__caption-s {
    @extend %govuk-table__caption-s;
  }

  &__caption-m {
    @extend %govuk-table__caption-m;
  }

  &__caption-l {
    @extend %govuk-table__caption-l;
  }

  &__caption-xl {
    @extend %govuk-table__caption-xl;
  }

  &__cell {
    code {
      font-size: 19px;
      font-weight: bold;
    }
  }
}


// Caption sizes
%govuk-table__caption-s {
  @include govuk-text-colour;
  font-size: 19px;

  margin-top: 0;
}

%govuk-table__caption-m {
  @include govuk-text-colour;
  font-size: 24px;

  margin-top: 0;
}

%govuk-table__caption-l {
  @include govuk-text-colour;
  font-size: 36px;

  margin-top: 0;
}

%govuk-table__caption-xl {
  @include govuk-text-colour;
  font-size: 48px;

  margin-top: 0;
}