// Home office pagination https://home-office-digital-patterns.herokuapp.com/components/pagination
.fds-pagination {
  padding: 0;
  @include govuk-text-colour;

  &__item {
    display: inline-block;
    list-style: none;
  }

  &__link {
    display: block;
    padding: 5px 10px;
    text-decoration: none;

    &:hover, &:focus {
      background: govuk-colour("light-grey");
      outline: 3px solid govuk-colour("yellow");
    }

    &--current {
      color: govuk-colour("black");
      font-weight: 700;
      border: none;
      pointer-events: none;
      cursor: default;

      &:hover, &:focus {
        color: govuk-colour("black");
        background: none;
      }
    }
  }

  &__list-items {
    li {
      display: inline-block;

      a {
        //@extend .bold-small;
        color: govuk-colour("blue");
        display: inline-block;
        padding: 15px 5px 10px 5px;
        margin-right: 15px;
        text-decoration: none;

        &:focus {
          outline: 0;
        }
      }

      &.active a,
      &.active a:hover {
        color: govuk-colour("blue");
        -webkit-box-shadow: inset 0px -5px 0px 0px govuk-colour("blue");
        -moz-box-shadow: inset 0px -5px 0px 0px govuk-colour("blue");
        box-shadow: inset 0px -5px 0px 0px govuk-colour("blue");
      }
    }
  }

  &__summary {
    @include govuk-typography-common;
    padding: 8px 0;

    @media (min-width: 642px) {
      float: right;
    }
  }
}