// Custom styling for GOVUK Checkboxes
.govuk-checkboxes {
  &--inline {
    display: flex;
  }

  &__label {
    &--wrap {
      @include fds-word-break;
    }
  }
}