// Back to top
.fds-back-to-top {
  margin-top: govuk-spacing(4);
  margin-bottom: govuk-spacing(6);

  @include govuk-media-query($from: tablet) {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: auto;
    margin-bottom: govuk-spacing(8);
  }

  &__icon {
    display: inline-block;
    width: .8em;
    height: 1em;
    margin-top: -(govuk-spacing(1));
    margin-right: govuk-spacing(2);
    vertical-align: middle;
  }
}

.js-enabled {
  .fds-back-to-top {
    &--fixed {
      position: fixed;
      top: calc(100% - #{govuk-spacing(8)});
      bottom: auto;
      left: auto;
    }

    &--hidden {
      .fds-back-to-top__link {
        @include govuk-visually-hidden-focusable;
      }
    }
  }
}