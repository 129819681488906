// FDS Search pattern
.fds-search-filters {
  @at-root .js-enabled & {
    @include govuk-media-query($until: tablet) {
      display: none;

      &--visible {
        display: block;
      }
    }
  }

  // Custom styling for form components in a search filter
  .govuk-input:not(:last-of-type) {
    @include govuk-responsive-margin(2, "bottom");
  }

  .govuk-select {
    min-width: 0;
    width: 100%;
  }

  .govuk-form-group {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__toggle-mobile {
    display: flex;
    justify-content: flex-start;
    @include govuk-responsive-margin(3, "bottom");
    padding: 0 15px;

    &--visible {
      @include govuk-media-query($from: tablet) {
        display: none;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__clear-link {
    @include govuk-font(19);
    text-align: right;
    margin-left: govuk-spacing(2);
  }

  &__heading {
    margin-bottom: 0;
  }

  &__list {
    list-style-type: none;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0;
    border-top: 1px solid govuk-colour("mid-grey");

    @include govuk-media-query($from: tablet)  {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__filter-button {
    margin-bottom: 0;
  }

  &__button-backdrop {
    position: sticky;
    background: govuk-colour("white");
    bottom: 0;
    padding: govuk-spacing(3);
    border-top: 1px solid govuk-colour("mid-grey");
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px;

    @include govuk-media-query($from: tablet)  {
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__item {
    border-bottom: 1px solid govuk-colour("mid-grey");
    padding: 15px 15px 12px 15px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &-component {
      margin-top: govuk-spacing(2);

      @at-root .js-enabled & {
        display: none;
      }

      @at-root .js-enabled &--visible {
        display: block;
      }

      // Otherwise the checkboxes break out of their expander container
      .govuk-checkboxes--small {
        .govuk-checkboxes__item {
          float: none;
        }
      }
      .govuk-radios--small {
        .govuk-radios__item {
          float: none;
        }
      }
    }
  }

  &__item-heading {
    margin: 0;
    padding: 0;

    &-button {
      display: block;
      font-weight: bold;
      position: relative;
      text-align: left;
      width: 100%;
      text-decoration: none;

      &:focus {
        @include govuk-focused-text;
      }

      @at-root .js-enabled & {
        color: govuk-colour("blue");
      }

      @at-root .js-enabled &:after {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        box-sizing: border-box;
        position: absolute;
        top: 10px;
        right: 0;
        border-left: 2px solid govuk-colour("dark-grey");
        border-bottom: 2px solid govuk-colour("dark-grey");
        transform: rotate(-225deg);
      }

      @at-root .js-enabled &--collapsed:after {
        top: 3px;
        transform: rotate(-45deg);
      }
    }
  }
}
