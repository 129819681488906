// FDS image preview
$preview-size-small: 100px;
$preview-size-medium: 150px;
$preview-size-large: 200px;

.fds-img-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: govuk-spacing(2);

  @include govuk-media-query($from: tablet) {
    margin-bottom: govuk-spacing(3);
  }

  &__link {
    @include govuk-responsive-padding(2, "all");
    margin: 0 govuk-spacing(2) govuk-spacing(2) 0;
    border: 1px solid $govuk-border-colour;
    display: flex;
    align-self: flex-start;
    flex-flow: column;
    align-items: center;

    &:last-child {
      margin: 0;
    }

    &:focus, &:focus-visible {
      outline: 3px solid govuk-colour("black");
      border-color: $govuk-focus-colour;
      box-shadow: none;
    }
  }

  &__caption {
    @include fds-word-break;
    @include govuk-font(16);
    margin-top: govuk-spacing(1);
    text-align: center;

    &--small {
      width: $preview-size-small;
    }

    &--medium {
      width: $preview-size-medium;
    }

    &--large {
      width: $preview-size-large;
    }

    @include govuk-media-query($until: tablet) {
      font-size: 16px;
    }
  }

  &__figure {
    margin: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &__img {
    width: auto;
    box-sizing: border-box;

    &--small {
      height: $preview-size-small;
    }

    &--medium {
      height: $preview-size-medium;
    }

    &--large {
      height: $preview-size-large;
    }

    @include govuk-media-query($until: tablet) {
      width: 100%;
      height: auto;
    }
  }
}
