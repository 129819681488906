.fds-datepicker {
  position: relative;

  &--disabled {
    color: #afafaf;

    &:focus {
      color: govuk-colour("black");
    }
  }

  &__input {
    @include govuk-responsive-margin(3, "bottom");
    width: auto;

    @include govuk-media-query($from: tablet) {
      margin-bottom: govuk-spacing(0);
      width: 14.4ex;
    }
  }

  &__button {
    margin-bottom: govuk-spacing(0);
  }

  &__dialog {
    $dialog-root: &;
    position: absolute;
    width: auto;
    clear: both;
    display: none;
    border: 3px solid govuk-colour("black");
    padding: 0;
    background-color: govuk-colour("white");
    @include govuk-font(19);

    &-button {
      display: flex;
      padding: 4px;
      border-style: none;
      background: transparent;
      cursor: pointer;

      &:focus {
        @include govuk-focused-text();

        #{$dialog-root}-svg {
          color: govuk-colour("black");
        }
      }

      &::-moz-focus-inner {
        border: 0;
      }
    }

    &-svg {
      height: 18px;
      width: 11px;
      stroke-width: 6px;
      stroke: govuk-colour("white");
      stroke-linecap: round;
      color: govuk-colour("white");

      &--prev {
        transform: rotate(180deg);
      }
    }
  }

  &__date-table {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;

    th, td {
      text-align: center;
    }
  }

  &__dialog-button-group {
    display: flex;
    justify-content: flex-end;
    @include govuk-responsive-margin(3, "bottom");
    @include govuk-responsive-margin(2, "top");
    margin-right: 1em;
    margin-left: 1em;
  }

  &__header {
    cursor: default;
    background-color: govuk-colour("black");
    padding: 7px;
    display: flex;
    justify-content: space-around;
  }

  &__heading {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: govuk-colour("white");
    text-transform: none;
    width: 50%;
    text-align: center;
  }

  &__message {
    padding-top: govuk-spacing(1);
    padding-bottom: govuk-spacing(1);
    padding-left: 1em;
    display: flex;
    align-items: center;
    background: govuk-colour("black");
    color: govuk-colour("white");
  }

  &__date-row {
    border: 1px solid black;
  }

  &__date-cell {
    outline: 0;
    border: 0;
    padding: 0;
    margin: 0;
    height: 40px;
    width: 40px;
  }

  &__date-button {
    @include govuk-font(16);
    padding: 0;
    margin: 0;
    line-height: inherit;
    height: 100%;
    width: 100%;
    border: 1px solid govuk-colour("light-grey");
    background: govuk-colour("light-grey");

    &:hover,
    &:focus {
      padding: 0;
      background-color: hsl(216, 80%, 92%);
    }

    &:focus {
      border-width: 2px;
      border-color: govuk-colour("dark-grey");
      outline: 0;
    }

    &[aria-selected] {
      border-color: govuk-colour("dark-grey");
    }

    &[tabindex="0"] {
      background-color: hsl(216, 80%, 92%);
    }

    &:disabled {
      color: #777;
      background-color: #fff;
      border: none;
      cursor: not-allowed;
    }
  }
}