// Fivium Design System Flash
.fds-flash {
  @include govuk-text-colour;
  @include govuk-responsive-padding(4);
  @include govuk-responsive-margin(8, "bottom");

  border: $govuk-border-width solid govuk-colour("blue");

  &:focus {
    outline: $govuk-focus-width solid $govuk-focus-colour;
  }

  &--red {
    border-color: govuk-colour("red");
  }

  &--green {
    border-color: govuk-colour("green");
  }

  &__title {
    @include govuk-font($size: 24, $weight: bold);

    margin-top: 0;
    @include govuk-responsive-margin(4, "bottom");

    &--only {
      margin-bottom: 0;
    }
  }

  &__content {
    @include govuk-font($size: 19);

    p {
      margin-top: 0;
      @include govuk-responsive-margin(4, "bottom");
    }
  }

  &__list {
    margin-top: 0;
    margin-bottom: 0;
  }
}