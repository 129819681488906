html.fds-has-modal {
  overflow: hidden;
}

// Modal wrappers styling
#fds-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background: rgba(0,0,0,0.5);
  overflow: auto;
  text-align: center;
}

#modal {
  background: #fff;
  width: auto;
  margin: 10% govuk-spacing(4) 140px govuk-spacing(4);
  @include govuk-responsive-padding(4, "all");
  position: relative;
  display: inline-block;
  text-align: left;
  box-sizing: border-box;
  @include govuk-font($size:19);
  @include govuk-text-colour;

  @include govuk-media-query($from: tablet) {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  //responsive modal
  &.dynamic-modal {
    width: auto;

    @include govuk-media-query($from: tablet) {
      min-width: 600px;
      margin-left: govuk-spacing(4);
      margin-right: govuk-spacing(4);
    }
  }
}


#fds-close-modal-fixed-button {
  display: block;
  margin-left: auto;
  margin-top: -5px;
  @include govuk-responsive-margin(3, "bottom");
}

.fds-modal-template {
  display: none;
}

// Modal content
.fds-modal {
  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__confirm-button,
  &__cancel-button {
    @include govuk-responsive-margin(0, "bottom");
  }
}