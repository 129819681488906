// Data Items Component
.fds-data-items-list {
  $root: &;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include govuk-responsive-margin(4, "top");
  @include govuk-responsive-margin(4, "bottom");
  @include govuk-font($size:19);
  @include govuk-text-colour;

  &--tight {
    justify-content: flex-start;

    #{$root}__container {
      margin-right: 1.5em;
    }
  }

  &__container {
    display: flex;
    flex-flow: column;
    flex: 1;
    margin-right: .5em;
    margin-bottom: .5em;

    &:last-child {
      margin-right: govuk-spacing(0);
    }

    &--width-1 {
      flex: 1;
    }

    &--width-2 {
      flex: 2;
    }

    &--width-3 {
      flex: 3;
    }

    &--width-4 {
      flex: 4;
    }
  }

  &__key {
    @include govuk-typography-weight-bold;
  }

  &__value-number {
    @include govuk-font($size:24, $weight:bold);
  }

  &__value-number-small {
    @include govuk-font($size:19, $weight:bold);
  }

  &__value,
  &__key-number {
    margin-left: govuk-spacing(0);
  }

  &--flow-column {
    flex-flow: column;

    #{$root}__container {
      @include govuk-responsive-margin(3, "bottom");
      margin-right: 0;

      &:last-child {
        @include govuk-responsive-margin(0, "bottom");
      }
    }
  }
}
