.hljs-comment,.hljs-quote {
  color: #545555;
  font-style: italic
}

.hljs-keyword,.hljs-selector-tag,.hljs-subst {
  color: #333333;
  font-weight: bold
}

.hljs-number,.hljs-literal,.hljs-variable,.hljs-template-variable,.hljs-tag .hljs-attr {
  color: #00703c
}

.hljs-string,.hljs-doctag {
  color: #d13118
}

.hljs-title,.hljs-section,.hljs-selector-id {
  color: #990000;
  font-weight: bold
}

.hljs-subst {
  font-weight: normal
}

.hljs-type,.hljs-class .hljs-title {
  color: #445588;
  font-weight: bold
}

.hljs-tag,.hljs-name,.hljs-attribute,.hljs-attr {
  color: #003078;
  font-weight: normal
}

.hljs-regexp,.hljs-link {
  color: #008020
}

.hljs-symbol,.hljs-bullet {
  color: #990073
}

.hljs-builtin,.hljs-builtin-name {
  color: #017ba5
}

.hljs-meta {
  color: #545555;
  font-weight: bold
}

.hljs-deletion {
  background: #ffdddd
}

.hljs-addition {
  background: #ddffdd
}

.hljs-emphasis {
  font-style: italic
}

.hljs-strong {
  font-weight: bold
}
