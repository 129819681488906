// Search selector
.fds-search-selector {
  &__input {
    display: flex;
    align-items: center;
    @include govuk-responsive-margin(2, "bottom");
  }

  &__button {
    margin-left: govuk-spacing(2);
    overflow: visible;
  }
}
