.select2-container {
  $root-container: &;

  &--focus, &--open {
    .select2-selection {
      @include focus-outline;
      border: $govuk-border-width-form-element solid $govuk-input-border-colour;

      box-shadow: inset 0 0 0 $govuk-border-width-form-element;
    }
  }

  &--default {
    &.select2-container--focus {
      .select2-selection--multiple {
        @include focus-outline;
        border: $govuk-border-width-form-element solid $govuk-input-border-colour;
      }
    }

    // Only show error border if we're not focused
    &:not(#{$root-container}--focus):not(#{$root-container}--open) {
      .select2-selection.fds-search-selector--error {
        border: $govuk-border-width-form-group-error solid $govuk-error-colour;
        // We should have govuk-spacing(1)'s worth of padding, so re-align element as top+left border influence the padding
        padding: ($govuk-border-width-form-group-error * 2) - govuk-spacing(1);
      }
    }

    .select2-search__field {
      @include govuk-font($size: 19);
    }

    .select2-search--inline {
      .select2-search__field {
        margin-top: 0;
      }
    }

    .select2-results {
      @include govuk-font($size: 19);

      // Vendor targets the aria-selected attr so we need to as well
      &__option--highlighted[aria-selected] {
        background-color: govuk-colour("light-blue");
        border: 1px solid transparent;
        padding: 5px;
      }
    }

    .select2-selection {
      @include selector-container;

      &--single, &--multiple {
        .select2-selection__rendered {
          color: govuk-colour("black");
        }
      }

      &--single {
        height: govuk-px-to-rem(40px);

        .select2-selection__rendered {
          padding-left: 0;

          &:not([title]) {
            display: none;
          }
        }

        .select2-selection__arrow {
          right: 3px;
          height: govuk-px-to-rem(40px);

          svg {
            color: govuk-colour('black');
            left: 50%;
            margin-left: -4px;
            margin-top: -2px;
            position: absolute;
            top: 50%;
          }
        }
      }

      &--multiple {
        display: flex;
        align-items: center;

        // Min height as we may need to expand to include multiple selections
        min-height: govuk-px-to-rem(40px);

        .select2-selection__rendered {
          display: flex;
          align-items: center;
          padding: 0;
        }

        .select2-selection__choice {
          border-radius: 0;
          margin-top: 0;
        }
      }
    }
  }

  .select2-dropdown {
    border-radius: 0;
  }
}

.select2-hidden-accessible {
  // The technique select2 uses to hide the <select> is inaccessible to Dragon, so we have to override it
  @include fds-hidden-form-control;
  border: 1px solid black !important;
  clip: auto !important;
  clip-path: none !important;
}
