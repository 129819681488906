// GOVUK Design System Sub Navigation
@include govuk-exports("fds-subnav") {

  .fds-subnav {
    $root: &;
    margin-bottom: govuk-spacing(0);
    padding: govuk-spacing(6) govuk-spacing(3) 0 0;

    @include govuk-media-query($from: tablet) {
      margin-bottom: 100px;
      @include govuk-font(16);
    }

    &--sticky {
      position: sticky;
      top: 0;
    }

    &__section {
      margin: 0 0 govuk-spacing(4);
      padding: 0;
      list-style-type: none;

      &-item {
        margin-bottom: govuk-spacing(1);
        padding-top: govuk-spacing(1);
        padding-bottom: govuk-spacing(1);

        &--current {
          $_current-indicator-width: 4px;
          margin-left: -(govuk-spacing(2) + $_current-indicator-width);
          padding-left: govuk-spacing(2);
          border-left: $_current-indicator-width solid govuk-colour("blue");
          background-color: govuk-colour("white");

          #{$root}__link {
            font-weight: bold;
          }
        }
      }

      &--nested {
        margin-top: govuk-spacing(2);
        margin-bottom: 0;
        padding-left: govuk-spacing(4);

        #{$root}__section-item::before {
          content: "—";
          margin-left: -(govuk-spacing(4));
          color: govuk-colour("dark-grey");
        }

        #{$root}__link {
          padding-left: 0;
          font-weight: normal;
        }
      }
    }

    &__link {
      padding: 2px 0;
      text-decoration: none;

      &:not(:focus):hover {
        color: $govuk-link-colour;
        text-decoration: underline;
      }
    }

    &__theme {
      margin: 0;
      padding: govuk-spacing(2) govuk-spacing(3) govuk-spacing(2) 0;
      color: govuk-colour("dark-grey");
      @include govuk-font(19);
    }
  }
}