// Custom GOVUK phase banner styles
.govuk-phase-banner {
  &--no-border {
    border-bottom: 0;
  }

  // Print styles
  @include govuk-media-query($media-type: print) {
    display: none;
  }
}
