.fds-file-upload-item__file-info-wrapper {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.fds-file-upload-dropzone__span {
  display: none;
}

.js-enabled .fds-file-upload-dropzone__span {
  display: inline;
}

.fds-file-upload-dropzone__hidden-input {
  display: none;
}

.js-enabled .fds-file-upload-dropzone__hidden-input {
  display: inline;
}

.fds-file-upload-dropzone__link {
  display: none;
}

.js-enabled .fds-file-upload-dropzone__link {
  display: inline;
}

.govuk-link.fds-file-upload-item__delete-link {
  display: none;
}

.js-enabled .govuk-link.fds-file-upload-item__delete-link {
  display: block;
}

.fds-file-upload-dropzone__hint-text {
  display: none;
}

.js-enabled .fds-file-upload-dropzone__hint-text {
  display: initial;
}
