// Custom styling for core typography

// Headings
.govuk-heading-xl,
.govuk-heading-l,
.govuk-heading-m,
.govuk-heading-s {
  &--no-wrap {
    word-break: keep-all;
  }
}

// Headings for when a component is hiding it's fieldset and legend wrapping elements
// e.g. Date input component
.fds-heading-no-fieldset--xl {
  @include govuk-font($size: 48, $weight: bold);
  margin-bottom: govuk-spacing(3);
  margin-top: govuk-spacing(0);
}

.fds-heading-no-fieldset--l {
  @include govuk-font($size: 36, $weight: bold);
  margin-bottom: govuk-spacing(3);
  margin-top: govuk-spacing(0);
}

.fds-heading-no-fieldset--m {
  @include govuk-font($size: 24, $weight: bold);
  margin-bottom: govuk-spacing(3);
  margin-top: govuk-spacing(0);
}

.fds-heading-no-fieldset--s {
  @include govuk-font($size: 19, $weight: bold);
  margin-top: govuk-spacing(0);
}

// Text
.fds-body-code-s {
  font-family: monospace;
  font-weight: bold;
  font-size: 19px;
}

.fds-body-code-m {
  font-family: monospace;
  font-weight: bold;
  font-size: 24px;
}

.fds-body-code-l {
  font-family: monospace;
  font-weight: bold;
  font-size: 36px;
}

.fds-body-code-xl {
  font-family: monospace;
  font-weight: bold;
  font-size: 48px;
}

// links
.govuk-link {
  &--stand-alone {
    @include govuk-font(19);
  }
}