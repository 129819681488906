// GOVUK Design System navigation

$navigation-height: 3.12rem;

.fds-navigation {
  @include govuk-font(19, $weight: bold);
  box-sizing: border-box;
  width: 100%;
  background-color: govuk-colour("light-grey");

  &__list {
    padding: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;

    @include govuk-media-query($from: tablet) {
      position: relative;
      left: -(govuk-spacing(3));
    }

    // reset 'govuk-width-container-wide' margin
    @include govuk-media-query($until: tablet) {
      margin: 0;
    }
  }

  &__list-item {
    &--current {
      border-left: 4px solid govuk-colour("blue");
    }

    @include govuk-media-query($from: tablet) {
      float: left;
      box-sizing: border-box;
      position: relative;
      height: $navigation-height;
      padding: 0 govuk-spacing(3);
      line-height: $navigation-height;

      &--current {
        border-bottom: 4px solid govuk-colour("blue");
        border-left: none;
      }
    }
  }

  &__link {
    @include govuk-typography-weight-bold;
    font-size: 19px;
    padding: 16px govuk-spacing(4) 17px govuk-spacing(4);
    text-decoration: none;
    display: block;

    @include govuk-media-query($from: tablet) {
      display: initial;
      padding: 0;

      &:not(:focus):hover {
        color: $govuk-link-colour;
        text-decoration: underline;
      }

      // Extend the touch area of the link to the list
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &:not(:focus):visited {
      color: $govuk-link-colour;
    }
  }
}

.fds-navigation__list-item--current .fds-navigation__link:hover {
  text-decoration: none;
}
