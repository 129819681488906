.fds-password {
  &__input-wrapper {
    display: table; // IE fallback
    display: flex;
    width: 100%;
    flex-direction: column;

    @include govuk-media-query($from: mobile) {
      flex-direction: row;
    }
  }

  &__input {
    display: table-cell;

    &:focus {
      z-index: 1;
    }

    &::-ms-reveal {
      display: none;
    }
  }

  &__toggle {
    @include govuk-font(19);
    z-index: 0;
    display: table-cell; // IE fallback
    padding: govuk-spacing(1) govuk-spacing(3);
    min-width: 5em; // stops the button width jumping when the text changes
    color: $govuk-link-colour;
    text-decoration: underline;
    background: govuk-colour("white");
    border: solid 2px $govuk-input-border-colour;
    white-space: nowrap;
    cursor: pointer;

    @include govuk-media-query($until: mobile) {
      padding: govuk-spacing(1);
      width: 100%;
      margin-top: -2px;
      white-space: normal;
    }

    @include govuk-media-query($from: mobile) {
      margin-left: -2px;
      margin-top: 0;
      margin-bottom: 0;
    }

    &:hover {
      color: $govuk-link-hover-colour;
      @include govuk-link-hover-decoration;
    }

    &:focus {
      z-index: 1;
      background: $govuk-focus-colour;
      color: $govuk-focus-text-colour;
      outline: 0;
    }

    &:active {
      z-index: 1;
      background: govuk-colour("white");
      border-color: $govuk-focus-colour;
      color: $govuk-link-active-colour;
    }
  }
}
