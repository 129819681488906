// GOVUK Design System masthead (full width big blue banner) component - https://design-system.service.gov.uk/
.fds-masthead {
  @include govuk-responsive-padding(6, "top");
  @include govuk-responsive-padding(6, "bottom");
  color: govuk-colour("white");
  background-color: govuk-colour("blue");

  &__title {
    color: govuk-colour("white");
    @include govuk-responsive-margin(6, "bottom");
  }

  &__description {
    @include govuk-font(24);
    margin-bottom: 0;
  }
}