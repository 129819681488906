// Fivium Design System Card
.fds-card {
  border: 5px solid govuk-colour("mid-grey");
  @include govuk-responsive-padding(3, "all");
  margin: govuk-spacing(3) 0;

  &__header {
    display: flex;
    align-items: center;
  }

  .govuk-heading-xl,
  .govuk-heading-l,
  .govuk-heading-m,
  .govuk-heading-s {
    margin-top: govuk-spacing(0);
    margin-bottom: govuk-spacing(0);
  }

  &__actions {
    display: flex;
  }

  &__link {
    @include govuk-font(19);
    margin-left: govuk-spacing(2);
  }

  &__files-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__files-list-item {
    word-wrap: break-word;

    &:last-child {
      .govuk-body {
        margin-bottom: govuk-spacing(0);
      }
    }

    .govuk-link {
      @include govuk-font(19);
    }
  }

  &--error {
    border-color: govuk-colour("red");
  }

  &--blue {
    border-color: govuk-colour("blue");
  }

  > .govuk-body:last-child, > .govuk-button:last-child, > a[role="button"]:last-child {
    margin-bottom: govuk-spacing(0);
  }
}