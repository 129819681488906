// Custom GOVUK inset text styles
.govuk-inset-text {
  &--red {
    border-left-color: govuk-colour("red");
  }

  &--blue {
    border-left-color: govuk-colour("blue");
  }

  &--yellow {
    border-left-color: govuk-colour("yellow");
  }

  &--green {
    border-left-color: govuk-colour("light-green");
  }
}