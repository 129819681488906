// Custom GOVUK header styles
.fds-header {
  //Custom styling for the standard GDS header
  // when combined with the sub navigation component
  &--navigation-menu {
    border-bottom-color: $govuk-brand-colour;

    .govuk-header__container {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &__link--homepage-hmg {
    display: inline-block;
  }

  &__logo--hmg {
    margin-bottom: 6px
  }

  &__hmg-logo {
    display: inline-flex;
    border-left: 2px solid currentColor;
    padding-left: 7.5px;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
  }

  &__hmg-logo-icon {
    display: inline-block;
    position: relative;
    fill: currentcolor;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__hmg-logo-text {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3157894737;
    text-wrap: balance;
  }

  &__product-name {
    &--fivium {
      margin-top: 14px;

      @include govuk-media-query($from: tablet) {
        margin-top: 9px;
      }
    }
  }

  // Print styles
  @include govuk-media-query($media-type: print) {
    display: none;
  }
}
