// Custom GOVUK button styles

// blue button - needs both base class and modifier e.g. class="govuk-button govuk-button--blue"
$govuk-button-colour-blue: govuk-colour("blue");
$govuk-button-hover-colour-blue: darken($govuk-button-colour-blue, 5%);
$govuk-button-shadow-colour-blue: darken($govuk-button-colour-blue, 15%);
$govuk-button-text-colour-blue: govuk-colour("white");
// negative
$govuk-button-colour-negative: govuk-colour("white");
$govuk-button-text-colour-negative: govuk-colour("blue");
$govuk-button-hover-colour-negative: #e6eff6;
$govuk-button-shadow-colour-negative: #004274;

$button-shadow-size: $govuk-border-width-form-element;

// mixin to define alternate button colours
@mixin button-alternate($colour, $bg-colour, $bg-hover-colour, $shadow-colour) {
  background-color: $bg-colour;
  box-shadow: 0 $button-shadow-size 0 $shadow-colour;

  &:hover,
  &:focus {
    background-color: $bg-hover-colour;
  }

  // Set text colour depending on background colour
  @if lightness($bg-colour) < 50% {
    color: govuk-colour("white");

    &:link,
    &:link:focus,
    &:hover,
    &:focus,
    &:visited {
      color: govuk-colour("white");
    }
  } @else if ($bg-colour == $govuk-button-colour-negative) {
    color: $govuk-button-text-colour-negative;

    &:link,
    &:link:focus,
    &:hover,
    &:focus,
    &:visited {
      color: $govuk-button-text-colour-negative;
    }
  } @else {
    color: $govuk-text-colour;

    &:link,
    &:link:focus,
    &:hover,
    &:focus,
    &:visited {
      color: $govuk-text-colour;
    }
  }
}

@mixin link-button {
  color: $govuk-link-colour;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  text-decoration: underline;
  border: 0;
  padding: 0;

  @include govuk-link-common;
  @include govuk-font-size(19);
  @include govuk-link-style-default;
  @include govuk-link-print-friendly;
}

// Begin adjustments for font baseline offset
// These should be removed when the font is updated with the correct baseline
// For the 1px addition please see https://github.com/alphagov/govuk-frontend/pull/365#discussion_r154349428

$offset: 2;

@mixin button-link {
  @include govuk-font($size: 19, $line-height: 19px);

  padding: (govuk-spacing(2) - $govuk-border-width-form-element - ($button-shadow-size / 2)) govuk-spacing(2);
  display: inline-block;
  border: 2px solid transparent;

  padding-top: (govuk-spacing(2) - $govuk-border-width-form-element - ($button-shadow-size / 2) + $offset);
  padding-bottom: (govuk-spacing(2) - $govuk-border-width-form-element - ($button-shadow-size / 2) - $offset + 1);

  &:focus {
    @include govuk-focused-text;
  }
}

//---------------------------------------------------------------------//

// BUTTON elements
//
// 0 padding to make link-buttons align the same as <a> tags
.fds-link-button {
  @include link-button;

  &--inline {
    margin-bottom: 0;
    line-height: inherit;
  }
}
.govuk-button {
  // blue button
  &--blue {
    @include button-alternate($govuk-button-text-colour-blue, $govuk-button-colour-blue, $govuk-button-hover-colour-blue, $govuk-button-shadow-colour-blue);
  }
  // white negative button
  &--negative {
    @include button-alternate($govuk-button-text-colour-negative, $govuk-button-colour-negative, $govuk-button-hover-colour-negative, $govuk-button-shadow-colour-negative);
    @include govuk-typography-weight-bold;
    margin-bottom: 0;
  }
  // colour change for header only
  &--header-link {
    font-size: inherit;
    color: #fff;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

  // Print styles
  @include govuk-media-query($media-type: print) {
    display: none;
  }
}

// ANCHOR elements
//
// an <a> tag styled to look like a link with button padding
// General use is when this action sits next to a primary action - e.g. 'Save and continue' or 'Next'
.govuk-link {
  &--button {
    @include button-link;
  }

  //Print styles
  @include govuk-media-query($media-type: print) {
    display: none;
  }
}
