.fds-file-upload-dropzone {
  border: 2px dashed govuk-colour("mid-grey");
  background: govuk-colour("light-grey");
  text-align: center;
  font-size: 1.2em;
  color: govuk-colour("black");
  transition: background 0.2s;
  margin-bottom: govuk-spacing(3);
  box-sizing: border-box;

  &--hidden {
    display: none;
  }

  &__content {
    padding: 2em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &--hover {
      background: #d1d1d1;
    }
  }

  &__text {
    &--hover {
      pointer-events: none;
    }
    @include govuk-font($size: 19, $line-height: 19px);
  }

  &__extensions {
    width: 100%;

    &--hover {
      pointer-events: none;
    }

    margin-top: govuk-spacing(3);

    .govuk-hint {
      margin-bottom: govuk-spacing(0);
    }
  }

  &__hidden-input {
    @include fds-hidden-form-control;
  }

  &__link {
    display: inline;
    font-weight: inherit;
    text-decoration: underline;
    color: $govuk-link-colour;
    cursor: pointer;
    padding: 0;
    background: transparent;

    &:hover {
      color: $govuk-link-hover-colour;
    }

    &:focus {
      @include govuk-focused-text;
    }

  }
}

.fds-file-upload-item {
  display: flex;
  flex-flow: column;
  @include govuk-responsive-margin(3, "bottom");
  border: 5px solid govuk-colour("mid-grey");
  padding: 1em;
  @include govuk-font($size: 19, $line-height: 19px);

  &--error {
    border-color: $govuk-error-colour;
  }

  &__filename {
    word-break: break-all;
  }

  &__extra-info,
  &__progress {
    margin-left: .3em;
  }

  &__error {
    color: $govuk-error-colour;
    @include govuk-typography-weight-bold;
    @include govuk-responsive-margin(2, "top");
    margin-bottom: govuk-spacing(0);
  }

  &__file-info-wrapper {
    display: flex;
  }

  &__info {
    .govuk-form-group {
      &--file-upload {
        @include govuk-responsive-margin(3, "top");
        @include govuk-responsive-margin(0, "bottom");
      }
    }

    .govuk-textarea {
      &--file-upload {
        @include govuk-responsive-margin(0, "bottom");
      }

      &--file-upload-character-count {
        @include govuk-responsive-margin(1, "bottom");
      }
    }

    .govuk-character-count {
      &--file-upload {
        @include govuk-responsive-margin(0, "bottom");
      }
    }
  }

  &__delete-link {
    margin-left: auto;
  }

  &--hidden {
    display: none;
  }
}
