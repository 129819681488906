$button-chevron-base-colour: govuk-colour("light-grey");
$button-chevron-colour: govuk-shade($button-chevron-base-colour, 40%);

.fds-action-dropdown {
  $root: &;
  display: inline-block;
  position: relative;
  align-self: center;

  &__button {
    padding-right: 27px;
    @include govuk-responsive-margin(0, "bottom");

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      box-sizing: border-box;
      position: absolute;
      top: 11px;
      right: 9px;
      border-left: 2px solid $button-chevron-colour;
      border-bottom: 2px solid $button-chevron-colour;
      transform: rotate(-45deg);
    }
  }

  &__list {
    position: absolute;
    border: 2px solid govuk-colour("mid-grey");
    list-style: none;
    padding: 0;
    background: govuk-colour("white");
    display: none;
    z-index: 10;
    overflow: hidden;
    margin: 1em 0;

    &-item {
      height: 39px;

      @include govuk-media-query($until: tablet) {
        height: auto;
      }

      &:hover {
        background: govuk-colour("light-grey");
      }
    }

    %actionItem {
      display: flex;
      padding: .3rem 1rem;
      text-decoration: none;
      white-space: nowrap;
    }

    #{$root}__item-button {
      @extend %actionItem;
      width: 100%;
    }

    #{$root}__item-link {
      @extend %actionItem;
      @include govuk-font($size: 19);
      margin: 0;
    }
  }
}
