// Slide out panel component

html {
  &.fds-slide-out-initialised {
    height: 100%;
    overflow: hidden;

    body {
      height: 100%;
    }

    #body-scroll {
      overflow: auto;
      height: 100%;
      background: govuk-colour("light-grey");
    }

    #body-wrapper {
      background: govuk-colour("white");
    }
  }

  &.fds-slide-out-visible {
    #body-wrapper {
      min-width: 960px;
    }
  }
}

#body-scroll {
  transition: width $transition-time;
}

.fds-body-scroll {
  width: 100%;

  &--with-slide-out {
    width: calc(100% - #{$slide-out-panel-width});

    // TODO: FDS-396
    @media (min-width: #{$govuk-page-width + $govuk-gutter * 2}) and (max-width: #{$slide-out-query-width}) {
      .govuk-width-container,
      .govuk-width-container-wide {
        margin-left: $govuk-gutter;
        margin-right: $govuk-gutter;

        @supports (margin: unquote("max(calc(0px))")) {
          $gutter-safe-area-right: -webkit-calc(#{$govuk-gutter-half} + env(safe-area-inset-right));
          $gutter-safe-area-right: calc(#{$govuk-gutter-half} + env(safe-area-inset-right));
          $gutter-safe-area-left: -webkit-calc(#{$govuk-gutter-half} + env(safe-area-inset-left));
          $gutter-safe-area-left: calc(#{$govuk-gutter-half} + env(safe-area-inset-left));

          // Use max() to pick largest margin, default or with safe area
          // Escaped due to Sass max() vs. CSS native max()
          margin-right: unquote("max(#{$govuk-gutter}, #{$gutter-safe-area-right})");
          margin-left: unquote("max(#{$govuk-gutter}, #{$gutter-safe-area-left})");
        }
      }
    }
  }
}

.fds-slide-out-panel {
  @include govuk-font(19);
  width: 350px;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: govuk-colour('white');
  padding: govuk-spacing(6);
  border-left: 1px solid #efefef;
  z-index: 100;
  transform: translate3d(100%, 0, 0);
  transition: transform $transition-time, opacity 0s $transition-time;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  overflow-y: auto;

  &--visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform $transition-time;
  }

  &__title {
    margin-bottom: 0;
  }

  &__heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: govuk-spacing(3);
  }
}
