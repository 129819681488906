// Custom styling for govuk-list
// https://design-system.service.gov.uk/styles/typography/#lists

.govuk-list {
  &--inline {
    margin-bottom: 0;
  }

  &__item {
    &--inline {
      display: inline;
      margin-right: 5px;
    }
  }
}