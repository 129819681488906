// Custom GOVUK text input styles

.govuk-input {
  &--read-only {
    box-shadow: none;
    outline: none;
    border: none;
    padding-left: 0;
    
    &:disabled {
      background: #fff;
      color: govuk-colour("black");
    }
  }

  // fixed width input - adding more to the current 5 options defined in govuk-frontend
  &--width-6 {
    width: 12.6ex;
  }

  &--width-7 {
    width: 14.4ex;
  }
}

