// Helpers to reduce duplication

@mixin selector-container {
  border: $govuk-border-width-form-element solid $govuk-input-border-colour;
  border-radius: 0;
  @include govuk-font($size: 19);
  color: govuk-colour("black");
  padding: govuk-spacing(1);
}

@mixin focus-outline {
  outline: $govuk-focus-width solid $govuk-focus-colour;
  outline-offset: 0;
}