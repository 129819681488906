// GOVUK Design System contact panel component (NOT full width big blue banner)
.fds-contact-panel {
  @include govuk-responsive-margin(6, "top");
  @include govuk-responsive-margin(6, "bottom");
  @include govuk-responsive-padding(3);
  background-color: govuk-colour("blue");
  border: 2px solid transparent;

  &--top {
    @include govuk-responsive-margin(0, "top");
  }

  .fds-contact-panel__heading,
  .fds-contact-panel__body,
  .fds-contact-panel__link,
  .fds-contact-panel__caption {
    color: govuk-colour("white");
  }

  .fds-contact-panel__link:hover {
    color: lighten(govuk-colour("light-blue"), 45%);
  }

  .fds-contact-panel__link:focus {
    color: $govuk-focus-text-colour;
  }
}

.fds-contact-panel__heading {

  &--m {
    @extend %govuk-heading-m;
  }

  &--l {
    @extend %govuk-heading-l;
  }

  &--xl {
    @extend %govuk-heading-xl;
  }
}

.fds-contact-panel__body {
  @include govuk-font(19);
  margin: govuk-spacing(0);
}