// Add a field
.fds-add-a-field {
  &__item {
    &-context {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__link {
    @include govuk-font(19);

    &--add {
      display: inline-block;
      @include govuk-responsive-margin(4, "bottom");
    }

    &--remove {
      margin-left: govuk-spacing(2);
    }
  }
}
