// GOVUK Design System mobile navigation

.app-mobile-nav {
  display: none;

  &--active {
    display: block;

    @include govuk-media-query($from: tablet) {
      display: none;
    }
  }
}

.no-js .app-mobile-nav {
  @include govuk-media-query($until: tablet) {
    display: block;
  }
}

.app-mobile-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: govuk-colour("light-grey");


  & > li {
    @include govuk-typography-common;
    @include govuk-typography-weight-bold;
    font-size: 19px; // We do not have a font mixin that produces 19px on mobile
    font-size: govuk-px-to-rem(19px); // sass-lint:disable-line no-duplicate-properties

    & > a {
      @include govuk-typography-weight-bold; // Override .govuk-link weight
      display: block;
      padding: 16px govuk-spacing(4) 17px govuk-spacing(4);
      text-decoration: none;

      &:hover,
      &:focus,
      &:visited {
        color: govuk-colour("blue");
        background-color: inherit;
      }
    }
  }
}

.app-mobile-nav__subnav {
  display: none;
  margin: 0;
  padding: govuk-spacing(2) 0 0 0;
  list-style: none;
  border-top: 1px solid govuk-colour("light-grey");
  border-bottom: 1px solid govuk-colour("light-grey");
  background-color: govuk-colour("white");

  & > li {
    & > a {
      @include govuk-font(19);
      display: block;
      padding: govuk-spacing(2) govuk-spacing(4);

      text-decoration: none;
    }

    &.current-page a {
      border-left: 4px solid govuk-colour("blue");
    }
  }
}

.js-enabled .app-mobile-nav__subnav--active {
  display: block;
}

.app-mobile-nav__current-page {
  border-left: 4px solid govuk-colour("blue");
}

.app-mobile-nav__theme {
  margin: 0;
  padding: govuk-spacing(4) govuk-spacing(4) govuk-spacing(1) govuk-spacing(4);
  color: govuk-colour("dark-grey");
  // Font is defined as a hard 19px so
  // it does not re-size on mobile viewport
  font-size: 19px;
  font-size: govuk-px-to-rem(19px); // sass-lint:disable-line no-duplicate-properties
  font-weight: 400;
}

.app-mobile-nav__theme-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: govuk-colour("white");

  & > li {
    @include govuk-font(19);

    & > a {
      display: block;
      padding: govuk-spacing(3) govuk-spacing(4) govuk-spacing(2) govuk-spacing(4);

      text-decoration: none;
    }

    &.current-page a {
      padding-left: govuk-spacing(4) - 4px;
      border-left: 4px solid govuk-colour("blue");
    }

    &:last-child a {
      padding-bottom: govuk-spacing(5);
    }
  }
}

.js-enabled .app-mobile-nav__subnav-toggler--active {
  border-top: 1px solid govuk-colour("white");
}