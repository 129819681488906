// Timeline pattern

.fds-timeline {
  @include govuk-responsive-margin(2, "top");

  &--left-padding {
    padding-left: inherit;
  }

  &__section {
    .govuk-heading-l {
      margin: 60px 0 40px 0;
    }

    &:first-child {
      .govuk-heading-l {
        margin-top: 0;
      }
    }
  }

  &__actions {
    display: flex;
    @include govuk-font(19);
    @include govuk-responsive-margin(2, "top");

    .govuk-link, .govuk-button {
      margin-right: govuk-spacing(2);
    }
  }

  &__length {
    margin-right: 1.5em;
  }

  &__time-stamps {
    padding-left: 25px;
    list-style-type: none;
    position: relative;
    @include govuk-responsive-margin(2, "top");
  }

  &__time-stamp {
    border-left: 4px solid govuk-colour("mid-grey");
    margin-left: -4px;
    @include govuk-responsive-padding(6, "bottom");
    @include govuk-responsive-padding(6, "left");
    top: -10px;
    position: relative;

    &--no-border {
      border-left-color: transparent;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .govuk-heading-m {
      margin-bottom: 0;
    }
  }

  &__node-number {
    @include govuk-font(24, bold);

    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border: 4px solid govuk-colour("mid-grey");
    background: govuk-colour("light-grey");
    position: absolute;
    border-radius: 50%;
    top: -7px;
    left: -24px;
  }

  &__events {
    padding-left: 0;
    list-style-type: none;
  }

  &__event {
    @include govuk-responsive-margin(4, "bottom");

    .govuk-heading-s {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}