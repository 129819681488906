// =========================================================
// Page layout
// =========================================================

// Custom styling for the GOVUK page measurements
$govuk-page-width-wide: 1200px;

// =========================================================
// Slide out panel
// =========================================================

// Transition time for animation
$transition-time: 300ms;

// Widths
$slide-out-panel-width: 350px + (govuk-spacing(6) * 2) + 1px;
$slide-out-query-width: (($govuk-page-width + $govuk-gutter * 2) + $slide-out-panel-width) - $govuk-gutter;
