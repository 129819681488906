// Placeholder styling for images - most likely to be replaced with a standalone component in the future
.fds-content-img {
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-top: 10px;
  border: 1px solid #b1b4b6;
  padding: 10px;
  margin-bottom: govuk-spacing(4);

  @include govuk-media-query($from: tablet) {
    margin-bottom: govuk-spacing(6);
  }
}