// A content wrapper for a custom page template not from govuk-frontend toolkit

.fds-content {
  padding: govuk-spacing(3) govuk-spacing(0);
  @include govuk-media-query($from: tablet) {
    padding: govuk-spacing(6);
    padding-right: 0;
  }
}

.fds-whats-new {
  border-bottom: 1px solid govuk-colour("mid-grey");
  background-color: #f8f8f8;
}