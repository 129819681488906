// Custom summary list styles
.fds-summary-list {
  &--error {
    padding-left: govuk-spacing(3);
    border-left: $govuk-border-width-form-group-error solid $govuk-error-colour;
  }

  &__error-message {
    @include govuk-responsive-margin(3, "top");
  }

  &__actions {
    @include govuk-responsive-margin(2, "bottom");
  }

  .govuk-link, .govuk-button {
    margin-right: govuk-spacing(2);
  }

  .govuk-link {
    @include govuk-font(19);
  }

  .govuk-summary-list {
    &--medium {
      .govuk-summary-list__row:first-child {
        .govuk-summary-list__key {
          @include govuk-media-query($from: tablet) {
            width: 40%;
          }
        }
      }
    }

    &--large {
      .govuk-summary-list__row:first-child {
        .govuk-summary-list__key {
          @include govuk-media-query($from: tablet) {
            width: 50%;
          }
        }
      }
    }
  }
}
